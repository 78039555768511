import {
  MARKETPLACE_SOURCE_ID,
  MARKETPLACE_GTTD_SOURCE_ID,
  CAMPAIGN_KEY,
  SOURCE_KEY
} from 'enums/marketplace'
import storageApi from 'helpers/storageApi'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

const useStoreMarketplaceReferrals = () => {
  const { query } = useRouter()

  const { widgetSlug } = query as {
    widgetSlug: string
  }

  useEffect(() => {
    if (query?.[SOURCE_KEY] !== MARKETPLACE_SOURCE_ID) {
      return
    }

    if (storageApi.getMarketplaceReferral({ widgetSlug }) === null) {
      storageApi.setMarketplaceReferral({
        widgetSlug,
        marketplace:
          query?.[CAMPAIGN_KEY] === MARKETPLACE_GTTD_SOURCE_ID ? 'gttd' : 'eola'
      })
    }
    // TODO #6362 - Fix ESLint - hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return {}
}

export default useStoreMarketplaceReferrals
